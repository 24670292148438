import Constants from '../../config.local.js'

var AccountingAccountMixin = {
	data() {
		return {

		}
	},
	methods: {
		// Récupère un article
		loadAccountingAccount: async function(accountingaccount_id) {
			const params = { 'accountingaccount_id': accountingaccount_id }
			const url = this.constructRoute(Constants.ACCOUNT_ACCOUNTING_GET_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingAccountMixin::loadAccountingAccount", url)
			.catch(error => {
				console.error("AccountingAccountMixin::loadAccountingAccount => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		// Ajoute un compte comptable
		addAccountingAccount: async function(accountingaccount_accountingplan, accountingaccount_vat, accountingaccount_label, accountingaccount_number, is_debours, is_avf, supplier = false) {
			const url = Constants.ACCOUNT_ACCOUNTING_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const infos = {
				"accountingaccount_accountingplan": accountingaccount_accountingplan,
				"accountingaccount_vat": accountingaccount_vat,
				"accountingaccount_label": accountingaccount_label,
				"accountingaccount_number": accountingaccount_number,
				"accountingaccount_debours": is_debours,
				"accountingaccount_avf": is_avf,
				"accountingaccount_supplier": supplier
			}

			const response = await this.$request.request_post_api("AccountingAccountMixin::addAccountingAccount", url, infos, false)
			.catch(e => {
				console.error("AccountingAccountMixin::addAccountingAccount", e)
				this.failureToast("toast.info_save_failed")
			})

			return response
		},

		// Modifie un compte comptable
		editAccountingAccount: async function(accountingaccount_id, accountingaccount_accountingplan, accountingaccount_vat, accountingaccount_label, accountingaccount_number, is_debours, is_avf, supplier = false) {
			const url = Constants.ACCOUNT_ACCOUNTING_URL+"/"+accountingaccount_id+"?licence_key="+Constants.USER_LICENCE_KEY

			const infos = {
				"accountingaccount_accountingplan": accountingaccount_accountingplan,
				"accountingaccount_vat": accountingaccount_vat,
				"accountingaccount_label": accountingaccount_label,
				"accountingaccount_number": accountingaccount_number,
				"accountingaccount_debours": is_debours,
				"accountingaccount_avf": is_avf,
				"accountingaccount_supplier": supplier
			}

			const response = await this.$request.request_post_api("AccountingAccountMixin::editAccountingAccount", url, infos, false)
			.catch(e => {
				console.error("AccountingAccountMixin::editAccountingAccount", e)
				this.failureToast("toast.info_save_failed")
			})

			return response
		},

		// Supprime un compte comptable
		deleteAccountingAccount (accountingaccount_id) {
			const url = `${Constants.ACCOUNT_ACCOUNTING_URL}/${accountingaccount_id}?licence_key=${Constants.USER_LICENCE_KEY}`

			return this.$request.request_delete_api("AccountingAccountMixin::deleteAccountingAccount", url)
		},


		loadAccountingPaymentAccount: async function(accountingpaymentaccount_id) {
			const params = { 'accountingpaymentaccount_id': accountingpaymentaccount_id }
			const url = this.constructRoute(Constants.PAYMENT_ACCOUNT_ACCOUNTING_GET_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingAccountMixin::loadAccountingPaymentAccounts", url)
			.catch(error => {
				console.error("AccountingAccountMixin::loadAccountingPaymentAccounts => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		// Ajoute un compte comptable
		addAccountingPaymentAccount: async function(accountingpaymentaccount_accountingplan, accountingpaymentaccount_number, accountingpaymentaccount_paymentmethod) {
			const url = Constants.PAYMENT_ACCOUNT_ACCOUNTING_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const infos = {
				"accountingpaymentaccount_accountingplan": accountingpaymentaccount_accountingplan,
				"accountingpaymentaccount_number": accountingpaymentaccount_number,
				"accountingpaymentaccount_paymentmethod": accountingpaymentaccount_paymentmethod,
			}

			const response = await this.$request.request_post_api("AccountingAccountMixin::addAccountingPaymentAccount", url, infos, false)
			.catch(e => {
				console.error("AccountingAccountMixin::addAccountingPaymentAccount", e)
				this.failureToast("toast.info_save_failed")
			})

			return response
		},

		// Modifie un compte comptable
		editAccountingPaymentAccount: async function(accountingpaymentaccount_id, accountingpaymentaccount_accountingplan, accountingpaymentaccount_number, accountingpaymentaccount_paymentmethod) {
			const url = Constants.PAYMENT_ACCOUNT_ACCOUNTING_URL+"/"+accountingpaymentaccount_id+"?licence_key="+Constants.USER_LICENCE_KEY

			const infos = {
				"accountingpaymentaccount_accountingplan": accountingpaymentaccount_accountingplan,
				"accountingpaymentaccount_number": accountingpaymentaccount_number,
				"accountingpaymentaccount_paymentmethod": accountingpaymentaccount_paymentmethod,
			}

			const response = await this.$request.request_post_api("AccountingAccountMixin::editAccountingPaymentAccount", url, infos, false)
			.catch(e => {
				console.error("AccountingAccountMixin::editAccountingPaymentAccount", e)
				this.failureToast("toast.info_save_failed")
			})

			return response
		},
	}
}

export default AccountingAccountMixin
