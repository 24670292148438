import Request from '@/assets/js/requests.js'
import Constants from '../../config.local.js'
import Common from '@/assets/js/common.js'

import ArticlesTypeTransformer from '@/assets/js/dexie/transformers/ArticlesTypeTransformer'
import ArticleTransformer from '@/assets/js/dexie/transformers/ArticleTransformer'

var ArticleMixin = {
	data() {
		return {

		}
	},
	methods: {
		// Ajoute un article
		addArticles: async function(articles_accountingaccount, articles_vat, articles_label, articles_code, articles_ht, articles_ttc, articles_articlestype = 1, articles_accountingaccountfdvex, articles_accountingaccountfdvxp, articles_vataccount, articles_vataccountfdvex, articles_vataccountfdvxp, articles_doublons = 1, articles_quantite, articles_commentaire) {
			const url = Constants.ARTICLES_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const infos = {
				"articles_accountingaccount": articles_accountingaccount,
				"articles_vat": articles_vat,
				"articles_label": articles_label,
				"articles_code": articles_code,
				"articles_ht": articles_ht,
				"articles_ttc": articles_ttc,
				"articles_articlestype": articles_articlestype,
				"articles_accountingaccountfdvex": articles_accountingaccountfdvex,
				"articles_accountingaccountfdvxp": articles_accountingaccountfdvxp,
				"articles_vataccount": articles_vataccount,
				"articles_vataccountfdvex": articles_vataccountfdvex,
				"articles_vataccountfdvxp": articles_vataccountfdvxp,
				"articles_doublons": articles_doublons,
				"articles_commentaire": articles_commentaire,
				"articles_quantite": articles_quantite
			}

			const response = await this.$request.request_post_api("ArticleMixin::addArticles", url, infos, false)
			.catch(e => {
				console.error("ArticleMixin::addArticles", e)
				this.failureToast("toast.info_save_failed")
			})

			return response
		},

		importArticle: async function(params) {
            const url = Constants.ARTICLES_IMPORT + "?licence_key="+Constants.USER_LICENCE_KEY

            const result = await this.$request.request_post_api("ArticleMixin::importArticle", url, {articles: params}, false)
            .catch(error => {
                console.error("ArticleMixin::importArticle => ERROR", error)
                if(error.response.data.message != "")
                {
                    this.failureToast(error.response.data.message)
                }
                else
                {
                    this.failureToast("toast.info_save_failed")
                }
                return null
            })

            return result
        },	

		// Modifie un article
		editArticles: async function(articles_id, articles_accountingaccount, articles_vat, articles_label, articles_code, articles_ht, articles_ttc, articles_inactive=false, articles_articlestype = 1, articles_accountingaccountfdvex, articles_accountingaccountfdvxp, articles_vataccount, articles_vataccountfdvex, articles_vataccountfdvxp, articles_doublons = 1, articles_quantite, articles_commentaire) {
			const url = Constants.ARTICLES_URL+"/"+articles_id+"?licence_key="+Constants.USER_LICENCE_KEY

			const infos = {
				"articles_accountingaccount": articles_accountingaccount,
				"articles_vat": articles_vat,
				"articles_label": articles_label,
				"articles_code": articles_code,
				"articles_ht": articles_ht,
				"articles_ttc": articles_ttc,
				"articles_inactive": articles_inactive,
				"articles_articlestype": articles_articlestype,
				"articles_accountingaccountfdvex": articles_accountingaccountfdvex,
				"articles_accountingaccountfdvxp": articles_accountingaccountfdvxp,
				"articles_vataccount": articles_vataccount,
				"articles_vataccountfdvex": articles_vataccountfdvex,
				"articles_vataccountfdvxp": articles_vataccountfdvxp,
				"articles_doublons": articles_doublons,
				"articles_commentaire": articles_commentaire,
				"articles_quantite": articles_quantite
			}

			const response = await this.$request.request_post_api("ArticleMixin::editArticles", url, infos, false)
			.catch(e => {
				console.error("ArticleMixin::editArticles", e)
				this.failureToast("toast.info_save_failed")
			})

			return response
		},

        deleteArticles: async function(articles_ids) {

        	const params = { 'articles_ids': articles_ids }

        	const url = Constants.ARTICLES_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_delete_api("AccountingMixin::loadDeleteArticle", url, params)
			.catch(e => {
				console.log(e);
				console.error("AccountingMixin::loadDeleteArticle", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response.retour
		},
		// Récupère un article
		loadArticle: async function(articles_id) {
			const params = { 'articles_id': articles_id }
			const url = this.constructRoute(Constants.ARTICLES_GET_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ArticleMixin::loadArticle", url)
			.catch(error => {
				console.error("ArticleMixin::loadArticle => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		getArticle: async function(articles_id) {
			return await this.$storage.db.t('articles')
			.then(table => {
			    return table.where({
					articles_id: parseInt(articles_id)
				})
				.first()
			})
			.then(item => {
                return ArticleTransformer.process(item)
            })
		},

		getArticlesType: async function() {
			return this.$storage.db.t('articles_type')
			.then(table => {
				return table.orderBy('articlestype_label')
			})
			.then(col => {
				return col.transform(new ArticlesTypeTransformer())
			})
		},

		saveArticleTva: async function(articles_ids, vat_id, vataccount_id, vataccountfdvxp_id, vataccountfdvex_id) {
			const url = Constants.ARTICLES_VAT_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_post_api("ArticleMixin::saveArticleTva", url, {articles_ids,vat_id,vataccount_id,vataccountfdvxp_id,vataccountfdvex_id}, false)
			.catch(e => {
				console.error("ArticleMixin::saveArticleTva", e)
				this.failureToast("toast.info_save_failed")
			})

			return response
		},

		saveArticleCompteComptable: async function(articles_ids, accountingaccount_id, accountingaccountfdvxp_id, accountingaccountfdvex_id) {
			const url = Constants.ARTICLES_ACCOUNTING_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_post_api("ArticleMixin::saveArticleTva", url, {articles_ids,accountingaccount_id,accountingaccountfdvxp_id,accountingaccountfdvex_id}, false)
			.catch(e => {
				console.error("ArticleMixin::saveArticleCompteComptable", e)
				this.failureToast("toast.info_save_failed")
			})

			return response
		}
	}
}

export default ArticleMixin
