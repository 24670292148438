import Constants from 'Constants'
import TierTransformer from '@/assets/js/dexie/transformers/TierTransformer'
import ContractCleaner from '@/assets/js/cache/cleaners/ContractCleaner'

var MonteReservationOrderMixin = {
	methods: {
		async getSaillieReservations(start_date = null, end_date = null) {

			let url = Constants.MONTE_RESA_LIST+"?licence_key="+Constants.USER_LICENCE_KEY
			if(start_date) {
				url += "&start_date=" + start_date.toDateInputValue()
			}
			if(end_date) {
				url += "&end_date=" + end_date.toDateInputValue()
			}
			
			return this.$request.request_get_api("MonteReservationOrderMixin::getSaillieReservations", url)
				.catch(e => {
					console.error("MonteReservationOrderMixin::getSaillieReservations => ", e)
					return null
				})
				.then(res => res.retour)
		},

		async getContractSailliesReservations(contract_id) {
			const id = this.$sync.replaceWithReplicated('contract', contract_id) 
			const url = this.constructRoute(Constants.CONTRACT_SAILLIE_RESA, { contract_id: id })+"?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_get_api("MonteReservationOrderMixin::getContractSailliesReservations", url)
				.catch(e => {
					console.error("MonteReservationOrderMixin::getContractSailliesReservations => ", e)
					return null
				})
				.then(res => res.retour)
		},

		async getSupplierSailliesReservations(tiers_id) {
			const id = this.$sync.replaceWithReplicated('tiers', tiers_id) 
			const url = this.constructRoute(Constants.TIERS_MONTE_RESA_URL, { tiers_id: id })+"?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_get_api("MonteReservationOrderMixin::getContractSailliesReservations", url)
				.catch(e => {
					console.error("MonteReservationOrderMixin::getContractSailliesReservations => ", e)
					return null
				})
				.then(res => res.retour)
		},

		async getContractSailliesReservation(reservation_id) {
			const url = this.constructRoute(Constants.CONTRACT_SAILLIE_RESA_DETAILS, { reservation_id })+"?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_get_api("MonteReservationOrderMixin::getContractSailliesReservation", url)
				.catch(e => {
					console.error("MonteReservationOrderMixin::getContractSailliesReservation => ", e)
					return null
				})
				.then(res => res.retour)
		},

		async addContractSailliesReservations(contract_id, params) {
			const url = this.constructRoute(Constants.CONTRACT_SAILLIE_RESA, { contract_id })+"?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_post_api("MonteReservationOrderMixin::addContractSailliesReservations", url, params, false)
				.catch(e => {
					console.error("MonteReservationOrderMixin::addContractSailliesReservations => ", e)
					throw e
				})
				.then(res => res.retour)
		},

		async editContractSailliesReservations(reservation_id, params) {
			const url = this.constructRoute(Constants.CONTRACT_SAILLIE_RESA_DETAILS, { reservation_id })+"?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_post_api("MonteReservationOrderMixin::editContractSailliesReservations", url, params, false)
				.catch(e => {
					console.error("MonteReservationOrderMixin::editContractSailliesReservations => ", e)
					throw e
				})
				.then(res => res.retour)
		},

		async deleteSaillieReservation(reservation_id) {
			const url = this.constructRoute(Constants.CONTRACT_SAILLIE_RESA_DETAILS, { reservation_id })+"?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_delete_api("MonteReservationOrderMixin::deleteSaillieReservation", url)
				.catch(e => {
					console.error("MonteReservationOrderMixin::deleteSaillieReservation => ", e)
					throw e
				})
		},

		async setReservationOrderStatus(reservation_id, dates) {
			const url = this.constructRoute(Constants.MONTE_RESA_ORDER, { reservation_id })+"?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_post_api("MonteReservationOrderMixin::setReservationOrderStatus", url, dates, false)
				.catch(e => {
					console.error("MonteReservationOrderMixin::setReservationOrderStatus => ", e)
					throw e
				})
				.then(res => res.retour)	
		},

		async notifyOrderExpediteur(reservation_ids, comment) {
			const url = Constants.MONTE_RESA_ORDER_NOTIFY+"?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_post_api("MonteReservationOrderMixin::notifyOrderExpediteur", url, { reservation_ids, comment }, false)
				.catch(e => {
					console.error("MonteReservationOrderMixin::notifyOrderExpediteur => ", e)
					throw e
				})
				.then(res => res.retour)	
		},

		async editExpediteurAndDate(reservation_ids, expediteur_id, date, date_modify, expediteur_modify) {
			let params = {}
			if(expediteur_modify && date_modify) {
				params = {expediteur_id, date}
			}
			else if(!expediteur_modify && date_modify) {
				params = {date}
			}
			else if(expediteur_modify && !date_modify) {
				params = {expediteur_id}
			}

			const url = this.constructRoute(Constants.MONTE_RESA_ORDER_EXPEDITEUR, { reservation_ids: reservation_ids.join() })+"?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_post_api("MonteReservationOrderMixin::editExpediteurAndDate", url, params, false)
				.catch(e => {
					console.error("MonteReservationOrderMixin::editExpediteurAndDate => ", e)
					throw e
				})
				.then(res => res.retour)
		},

		async loadCentreStockage() {
			const type = await this.$storage.db.t('qualification')
				.then(table => {
					return table.where({
						qualification_code: 'centre_stockage'
					})
				})
				.then(col => {
					return col.first()
				})

			const qualif_link = await this.$storage.db.t('qualification_link')
			    .then(table => {
			    	return table.where({
			    		qualificationlink_qualification: type.qualification_id,
			    	})
			    }).then(col => {
					return col.toArray()
				})

			const tiers_ids = qualif_link.map(qualif => qualif.qualificationlink_fk)

			const tiers = await this.$storage.db.t('tiers')
				.then(table => {
					return table
					.where('tiers_id').anyOf(tiers_ids)
					.and(tier => tier.tiers_archive == 0)
				})
				.then(col => {
					return col.transform(new TierTransformer('light'))
				})

			return tiers
		},

        forceIndexationContractResa: async function(contract_ids) {
            ContractCleaner.inst().onMutation(contract_ids, ['paillettes', 'doses'])
        },
	}
}

export default MonteReservationOrderMixin